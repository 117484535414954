<template>
  <div class="h-full overflow-y-auto">
    <div class="container grid px-10 py-2 mx-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
