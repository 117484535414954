import {
  WorkSpaceIcon,
  ClientIcon,
  AppointmentIcon,
  PaymentIcon,
  TasksIcon,
  MessageIcon,
  ReportIcon
} from '../../images'

const adminRoutes = [
  {
    name: 'Dashboard',
    icon: WorkSpaceIcon,
    link: { name: 'Dashboard' }
  },
  {
    name: 'Staff',
    icon: ClientIcon,
    link: { name: 'Staffs' }
  },
  {
    name: 'Report',
    icon: ReportIcon,
    innerRoutes: [
      {
        name: 'Payment Report',
        link: { name: 'Payment' }
      },
      {
        name: 'Client Report',
        link: { name: 'Client' }
      }
    ]
  },
  {
    name: 'Messages',
    icon: MessageIcon,
    link: { name: 'Message' }
  }
]

const managerRoutes = [
  {
    name: 'Workspace',
    icon: WorkSpaceIcon,
    link: { name: 'Workspace' }
  },
  {
    name: 'Clients',
    icon: ClientIcon,
    matches: ['Client', 'AddClient', 'ClientProfile'],
    link: { name: 'Client' }
  },
  {
    name: 'Schedule Appointment',
    icon: AppointmentIcon,
    link: { name: 'Appointment' }
  },
  // {
  //   name: 'Case',
  //   icon: ReportIcon,
  //   link: { name: 'Case' }
  // },
  {
    name: 'Invoice',
    icon: PaymentIcon,
    link: { name: 'Payment' }
  },
  // {
  //   name: 'Task',
  //   icon: TasksIcon,
  //   link: { name: 'Tasks' }
  // },
  {
    name: 'Message',
    icon: MessageIcon,
    link: { name: 'Message' }
  }
  // {
  //   name: 'Report',
  //   icon: ReportIcon,
  //   innerRoutes: [
  //     {
  //       name: 'Activity Report',
  //       link: { name: 'ActivityReport' }
  //     },
  //     {
  //       name: 'Tasks Report',
  //       link: { name: 'TaskReport' }
  //     }
  //   ]
  // }
]

const consultantRoutes = [
  {
    name: 'Workspace',
    icon: WorkSpaceIcon,
    link: { name: 'Workspace' }
  },
  {
    name: 'Clients',
    icon: ClientIcon,
    matches: ['Client', 'AddClient', 'ClientProfile'],
    link: { name: 'Client' }
  },
  // {
  //   name: 'Task',
  //   icon: TasksIcon,
  //   link: { name: 'TaskList' }
  // },
  {
    name: 'Appointment',
    icon: ReportIcon,
    link: { name: 'AppointmentList' }
  }
  // {
  //   name: 'Case',
  //   icon: ReportIcon,
  //   link: { name: 'Case' }
  // }
]

const routes = [
  {
    name: 'Workspace',
    icon: WorkSpaceIcon,
    link: { name: 'Workspace' }
  },
  {
    name: 'Dashboard',
    icon: WorkSpaceIcon,
    link: { name: 'Dashboard' }
  },
  {
    name: 'Clients',
    icon: ClientIcon,
    matches: ['Client', 'AddClient', 'ClientProfile'],
    link: { name: 'Client' }
  },
  {
    name: 'Schedule Appointment',
    icon: AppointmentIcon,
    link: { name: 'Appointment' }
  },
  {
    name: 'Case',
    icon: ReportIcon,
    link: { name: 'Case' }
  },
  {
    name: 'Appointment',
    icon: ReportIcon,
    link: { name: 'AppointmentList' }
  },
  {
    name: 'Payment',
    icon: PaymentIcon,
    link: { name: 'Payment' }
  },
  {
    name: 'Task',
    icon: TasksIcon,
    link: { name: 'Tasks' }
  },
  {
    name: 'Task List',
    icon: TasksIcon,
    link: { name: 'TaskList' }
  },
  {
    name: 'Message',
    icon: MessageIcon,
    link: { name: 'Message' }
  },
  {
    name: 'Report',
    icon: ReportIcon,
    innerRoutes: [
      {
        name: 'Activity Report',
        link: { name: 'ActivityReport' }
      },
      {
        name: 'Tasks Report',
        link: { name: 'TaskReport' }
      }
    ]
  }
]

export { routes, adminRoutes, managerRoutes, consultantRoutes }
