<template>
  <div class="flex h-screen bg-gray-50 relative">
    <div class="absolute top-0 left-0 right-0"></div>
    <sidebar />
    <div class="flex flex-col flex-1 w-full">
      <the-header />
      <p class="hidden bg-red-50 border-red-500 text-red-700"></p>
      <p class="hidden bg-green-50 border-green-500"></p>
      <p class="hidden relative mx-auto z-50 w-2xl max-w-2xl px-3 py-12"></p>
      <notification />
      <main-container class="mb-10">
        <router-view />
      </main-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '../components/Sidebar/Sidebar.vue'
import TheHeader from '../components/TheHeader.vue'
import Notification from '../components/Utils/Notification.vue'
import MainContainer from './MainContainer.vue'
export default {
  components: { Sidebar, TheHeader, MainContainer, Notification },
  computed: {
    ...mapState({
      tokenExpired: state => state.security.tokenExpired
    })
  }
}
</script>

<style></style>
