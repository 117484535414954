import Vue from 'vue'
import VueRouter from 'vue-router'
import TheAuth from '../layout/TheAuth.vue'

import TheLayout from '../layout/TheLayout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: TheAuth,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "login" */ '../views/theAuth/Login.vue'),
        meta: {
          title: 'Login'
        }
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ '../views/theAuth/ForgetPassword.vue'
          ),
        meta: {
          title: 'Forget Password'
        }
      },
      {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ '../views/theAuth/ResetPassword.vue'
          ),
        meta: {
          title: 'Reset Password'
        }
      }
    ]
  },
  {
    path: '/dashboard',
    redirect: '/workspace/me',
    component: TheLayout,
    children: [
      {
        name: 'Workspace',
        path: '/workspace/me',
        meta: {
          title: 'Workspace'
        },
        component: () =>
          import(
            /* webpackChunkName: "workspace" */ '../views/dashboard/Workspace.vue'
          )
      },
      {
        name: 'Dashboard',
        path: '/dashboard/me',
        meta: {
          title: 'Dashboard'
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardAdmin.vue'
          )
      },
      {
        name: 'Client',
        path: '/dashboard/clients',
        meta: {
          title: 'Clients'
        },
        component: () =>
          import(/* webpackChunkName: "client" */ '../views/client/Client.vue')
      },
      {
        name: 'UserProfile',
        path: '/dashboard/user-profile',
        meta: {
          title: 'My Profile'
        },
        component: () =>
          import(/* webpackChunkName: "client" */ '../views/user/Profile.vue')
      },
      {
        name: 'EditClient',
        path: '/dashboard/client/edit/:id',
        meta: {
          title: 'Edit Client'
        },
        component: () =>
          import(
            /* webpackChunkName: "client" */ '../views/client/EditClient.vue'
          )
      },
      {
        name: 'Staffs',
        path: '/dashboard/staffs',
        meta: {
          title: 'Staff'
        },
        component: () =>
          import(/* webpackChunkName: "staff" */ '../views/staffs/Staff.vue')
      },
      {
        name: 'ClientProfile',
        path: '/dashboard/client/profile/:id',
        meta: {
          title: 'Profile'
        },
        component: () =>
          import(
            /* webpackChunkName: "client-profile" */ '../views/client/Profile.vue'
          )
      },
      {
        name: 'AddClient',
        path: '/dashboard/client/add',
        meta: {
          title: 'New Clients'
        },
        component: () =>
          import(
            /* webpackChunkName: "add-client" */ '../views/client/AddClient.vue'
          )
      },
      {
        name: 'Appointment',
        path: '/dashboard/appointments',
        meta: {
          title: 'Schedule Appointment'
        },
        component: () =>
          import(
            /* webpackChunkName: "appointment" */ '../views/appointment/Appointment.vue'
          )
      },
      {
        name: 'AppointmentList',
        path: '/dashboard/appointment-list',
        meta: {
          title: 'Appointments'
        },
        component: () =>
          import(
            /* webpackChunkName: "appointment-list" */ '../views/appointment/AppointmentList.vue'
          )
      },
      {
        name: 'Message',
        path: '/dashboard/messages',
        meta: {
          title: 'Messages'
        },
        component: () =>
          import(
            /* webpackChunkName: "message" */ '../views/message/Message.vue'
          )
      },
      {
        name: 'Payment',
        path: '/dashboard/payment',
        meta: {
          title: 'Payment'
        },
        component: () =>
          import(
            /* webpackChunkName: "payment" */ '../views/payment/Invoice.vue'
          )
      },
      {
        name: 'CreateInvoice',
        path: '/dashboard/invoice/create',
        meta: {
          title: 'Create Invoice'
        },
        component: () =>
          import(
            /* webpackChunkName: "create-invoice" */ '../views/payment/CreateInvoice.vue'
          )
      },
      {
        name: 'PaymentList',
        path: '/dashboard/invoice/:invoiceId/payments',
        meta: {
          title: 'Payment List'
        },
        component: () =>
          import(
            /* webpackChunkName: "create-invoice" */ '../views/payment/PaymentList.vue'
          )
      },
      {
        name: 'Report',
        path: '/dashboard/report/activity',
        meta: {
          title: 'Report'
        },
        component: () =>
          import(/* webpackChunkName: "report" */ '../views/report/Report.vue')
      },
      {
        name: 'ActivityReport',
        path: '/dashboard/report/activity',
        meta: {
          title: 'Activity Report'
        },
        component: () =>
          import(
            /* webpackChunkName: "report-activity" */ '../views/report/ActivityReport.vue'
          )
      },
      {
        name: 'PaymentReport',
        path: '/dashboard/report/payment',
        meta: {
          title: 'Payment Report'
        },
        component: () =>
          import(
            /* webpackChunkName: "report-activity" */ '../views/payment/PaymentReport.vue'
          )
      },
      {
        name: 'TaskReport',
        path: '/dashboard/report/task',
        meta: {
          title: 'Task Report'
        },
        component: () =>
          import(
            /* webpackChunkName: "report-activity" */ '../views/report/TaskReports.vue'
          )
      },
      {
        name: 'Tasks',
        path: '/dashboard/tasks',
        meta: {
          title: 'Tasks'
        },
        component: () =>
          import(/* webpackChunkName: "task" */ '../views/task/Task.vue')
      },
      {
        name: 'TaskList',
        path: '/dashboard/task-list',
        meta: {
          title: 'Tasks'
        },
        component: () =>
          import(
            /* webpackChunkName: "task-list" */ '../views/task/TaskList.vue'
          )
      },
      {
        name: 'Case',
        path: '/dashboard/case/:clientId',
        meta: {
          title: 'Case'
        },
        component: () =>
          import(/* webpackChunkName: "case" */ '../views/case/Case.vue')
      },
      {
        name: 'EditCase',
        path: '/dashboard/case/:clientId/:caseId',
        meta: {
          title: 'Edit Case'
        },
        component: () =>
          import(
            /* webpackChunkName: "edit-case" */ '../views/case/EditCase.vue'
          )
      }
    ]
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | Jonathan King`
  }
})

export default router
