<template>
  <header class="z-40 py-4 bg-white shadow-bottom">
    <div
      class="container flex items-center md:justify-end justify-between h-full px-6 mx-auto text-primary"
    >
      <!-- Mobile Hamburger -->
      <button
        @click="toggleSidebar"
        class="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
      >
        <img
          alt="menu"
          src="../assets/svgs/ham_menu.svg"
          class="w-6 h-6"
          aria-hidden="true"
        />
      </button>
      <ul class="flex items-center flex-shrink-0 space-x-6">
        <!-- Notification -->
        <li class="relative">
          <button
            class="rounded-full focus:outline-none overflow-hidden hidden"
          >
            <avatar :src="BellIcon" size="regular" />
          </button>
        </li>

        <li class="flex items-center">
          <p
            class="font-semibold text-gray-800 hidden md:block text-sm tracking-wide"
          >
            {{ user.firstName }}
          </p>
        </li>
        <!-- <li class="flex items-center">
          <p
            @click="logout"
            class="font-semibold text-red-500 text-sm tracking-wide cursor-pointer"
          >
            Logout
          </p>
        </li> -->
        <!-- Profile -->
        <li class="relative">
          <button
            @click="showDropdown = !showDropdown"
            class="rounded-full focus:outline-none overflow-hidden"
          >
            <svg
              class="w-7 h-7"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              focusable="false"
              width="1em"
              height="1em"
              style="
                -ms-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              "
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"
                fill="#626262"
              />
            </svg>
          </button>
          <div
            v-if="showDropdown"
            class="absolute right-3 top-8 py-2 px-4 bg-white rounded shadow"
          >
            <div class="flex flex-col">
              <router-link
                :to="{ name: 'UserProfile' }"
                @click="showDropdown = false"
                class="font-light py-1 text-gray-600 text-sm tracking-wide cursor-pointer"
              >
                Profile
              </router-link>
              <p
                @click="logout"
                class="font-light py-1 text-red-500 text-sm tracking-wide cursor-pointer"
              >
                Logout
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { MenuIcon, BellIcon } from '../images'
import Avatar from './Utils/Avatar.vue'
export default {
  components: { Avatar },
  data: () => ({
    MenuIcon,
    BellIcon,
    showDropdown: false
  }),
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    toggleSidebar() {
      this.$store.commit('toggleSidebar', true)
    },
    logout() {
      this.$router.push({ name: 'Login' })
      this.$store.commit('updateUserData', {})
      this.$store.commit('setToken', '')
    }
  }
}
</script>

<style></style>
