<template>
  <aside
    class="sidebar z-30 flex-shrink-0 hidden lg:block w-64 h-full overflow-y-auto bg-white"
  >
    <sidebar-content />
  </aside>
</template>

<script>
import SidebarContent from './SidebarContent.vue'
export default {
  components: { SidebarContent }
}
</script>

<style scoped>
.sidebar::-webkit-scrollbar {
  background: #f3f3f3;
  border-radius: 8px;
  height: 8px;
  width: 5px;
  margin-top: 5px;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #ab201f;
}
.sidebar::-webkit-scrollbar-track {
  background-color: #fcf7eb;
}
</style>
