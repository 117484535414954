<template>
  <t-alert
    style="
      right: 15px;
      text-transform: capitalize;
      top: 30px;
      z-index: 5000;
      position: fixed;
    "
    :variant="`${alert.error === true ? 'danger' : 'success'}`"
    :timeout="alert.timeout"
    :show="alert.status === true"
  >
    {{ alert.message }}
  </t-alert>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Alert',
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  }
}
</script>

<style></style>
