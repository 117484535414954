<template>
  <div
    :class="
      `relative rounded-full inline-block ${
        size === 'xlarge'
          ? 'w-20 h-20'
          : size === 'large'
          ? 'w-10 h-10'
          : size === 'regular'
          ? 'w-8 h-8'
          : size === 'small'
          ? 'w-6 h-6'
          : 'w-6 h-6'
      } `
    "
  >
    <img
      class="object-cover w-full h-full rounded-full"
      :src="src"
      :alt="alt"
      loading="lazy"
    />
    <div
      class="absolute inset-0 rounded-full shadow-inner"
      aria-hidden="true"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    src: { type: String, required: true },
    alt: { type: String, required: false, default: '' },
    size: { type: String, default: 'regular' }
  }
}
</script>

<style></style>
