import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHtmlToPaper from 'vue-html-to-paper'
// import './registerServiceWorker'

import VueTailwind from 'vue-tailwind'
import vSelect from 'vue-select'

import { Settings } from './plugins/vue-tailwind-settings'
import titleMixin from './mixins/title-mixin'

import './assets/tailwind.css'
import './assets/css/main.css'
import 'vue-select/dist/vue-select.css'
import printCss from './assets/css/print.css'

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    printCss
    // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    // 'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.config.productionTip = false
Vue.mixin(titleMixin)
Vue.use(VueTailwind, Settings)
Vue.component('v-select', vSelect)
Vue.use(VueHtmlToPaper, options)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// TODO: Use vue-tailwind rich select remove v-select
