var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("relative rounded-full inline-block " + (_vm.size === 'xlarge'
        ? 'w-20 h-20'
        : _vm.size === 'large'
        ? 'w-10 h-10'
        : _vm.size === 'regular'
        ? 'w-8 h-8'
        : _vm.size === 'small'
        ? 'w-6 h-6'
        : 'w-6 h-6') + " ")},[_c('img',{staticClass:"object-cover w-full h-full rounded-full",attrs:{"src":_vm.src,"alt":_vm.alt,"loading":"lazy"}}),_c('div',{staticClass:"absolute inset-0 rounded-full shadow-inner",attrs:{"aria-hidden":"true"}})])}
var staticRenderFns = []

export { render, staticRenderFns }