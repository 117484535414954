<template>
  <div v-show="isSidebarOpen">
    <transition
      enter="transition ease-in-out duration-150"
      enterFrom="opacity-0 transform -translate-x-20"
      enterTo="opacity-100"
      leave="transition ease-in-out duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 transform -translate-x-20"
    >
      <aside
        v-click-outside="onClickOutside"
        class="fixed inset-y-0 z-50 flex-shrink-0 w-64 overflow-y-auto bg-white lg:hidden"
      >
        <sidebar-content />
      </aside>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidebarContent from './SidebarContent.vue'
import vClickOutside from 'v-click-outside'
export default {
  components: { SidebarContent },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    ...mapState({
      isSidebarOpen: state => state.mobileSidebarOpen
    })
  },
  data: () => ({}),
  methods: {
    onClickOutside(event) {
      this.$store.commit('toggleSidebar', false)
    }
  }
}
</script>

<style></style>
