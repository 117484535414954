<template>
  <li class="relative px-6 py-4">
    <button
      @click="toggleDropdown"
      class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 focus:outline-none"
      aria-haspopup="true"
    >
      <span class="inline-flex items-center">
        <img class="w-5 h-5" aria-hidden="true" :src="route.icon" />
        <span class="ml-4">{{ route.name }}</span>
      </span>
      <img
        :class="
          ` ${
            isDropDownMenuOpen
              ? 'transform rotate-180 duration-150 '
              : 'duration-150 transition-transform'
          } `
        "
        :src="DropdownIcon"
      />
    </button>
    <transition :appear="true" appear-class="fadeIn">
      <div v-show="isDropDownMenuOpen">
        <ul
          class="p-2 mt-3 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50"
          aria-label="submenu"
        >
          <li
            v-for="(r, i) in route.innerRoutes"
            :key="i"
            class="px-2 py-1 cursor-pointer transition-colors duration-150 hover:text-gray-800"
          >
            <span @click="toggleSidebar(r.link)" class="w-full">
              {{ r.name }}
            </span>
          </li>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
import { DropdownIcon } from '../../images'

export default {
  data() {
    return {
      DropdownIcon,
      isDropDownMenuOpen: false
    }
  },
  props: {
    route: {
      type: Object
    }
  },
  mounted() {},
  methods: {
    toggleDropdown() {
      this.isDropDownMenuOpen = !this.isDropDownMenuOpen
    },
    toggleSidebar(link) {
      this.$store.commit('toggleSidebar', false)
      if (this.$route.name.toLowerCase() === link.name.toLowerCase()) return
      this.$router.push(link)
    }
  }
}
</script>

<style></style>
