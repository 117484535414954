import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import base64 from 'base-64'
import {
  adminRoutes,
  consultantRoutes,
  managerRoutes,
  routes
} from '../components/Sidebar/routes'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'jk'
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    mobileSidebarOpen: false,
    sidebarContent: {},
    token: '',
    security: {
      tokenExpired: false
    },
    alert: {
      status: false,
      message: '',
      error: false,
      timeout: 5000
    }
  },
  getters: {
    getRequestHeaders: state => ({
      headers: {
        Authorization: 'Bearer ' + base64.decode(state.token)
      }
    }),
    getUserRole: state => state?.user?.role,
    sidebarContent: state => {
      // admin, superadmin, manager, consultant
      if (state.user.role === 'admin' || state.user.role === 'superadmin') {
        return adminRoutes
      } else if (state.user.role === 'manager') {
        return managerRoutes
      } else if (state.user.role === 'consultant') {
        return consultantRoutes
      } else {
        return routes
      }
    }
  },
  mutations: {
    toggleSidebar(state, data) {
      state.mobileSidebarOpen = data
    },
    setTokenExpiredState(state, status) {
      state.security.tokenExpired = status
    },
    alert(state, data) {
      state.alert = { ...state.alert, ...data }
      setTimeout(() => {
        state.alert = {
          message: '',
          status: false,
          error: false
        }
      }, 5000)
    },
    updateUserData(state, user = {}) {
      if (Object.keys(user).length === 0) {
        state.notifications = []
        state.wallet = {}
        state.user = {}
        return
      }
      state.user = user
    },
    setToken(state, token) {
      if (token === '') {
        localStorage.removeItem('jwt')
        state.token = ''
        return
      }
      const encodedToken = base64.encode(token)
      localStorage.setItem('jwt', encodedToken)
      state.token = encodedToken
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})
