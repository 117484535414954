<template>
  <div>
    <desktop-sidebar />
    <mobile-sidebar />
  </div>
</template>

<script>
import DesktopSidebar from './DesktopSidebar.vue'
import MobileSidebar from './MobileSidebar.vue'
export default {
  components: { DesktopSidebar, MobileSidebar }
}
</script>

<style></style>
