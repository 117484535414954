<template>
  <div class="flex items-center min-h-screen p-6 bg-gray-50">
    <div
      class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl"
    >
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img :src="MainBg" class="object-cover w-full h-full" />
        </div>
        <main class="flex items-center justify-end p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <router-view />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { MainBg } from '../images'
export default {
  data: () => ({
    MainBg
  })
}
</script>

<style></style>
