<template>
  <div class="py-4 text-gray-500">
    <a href="#" class="ml-6 text-center text-accent text-lg font-bold block">
      Jonathan King
    </a>
    <!-- TODO: Give equal spacing between menu items -->
    <ul class="mt-6">
      <template v-for="(route, i) in routes">
        <template v-if="route.innerRoutes">
          <sidebar-sub-menu :route="route" :key="i" />
        </template>
        <template v-else>
          <li class="relative cursor-pointer px-6 py-4" :key="i">
            <span
              @click="toggleSidebar(route.link)"
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
              active-class="text-primary"
            >
              <div>
                <span
                  v-if="
                    $route.name.toLowerCase() ===
                      (route.link ? route.link.name.toLowerCase() : '')
                  "
                  class="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                >
                </span>
              </div>
              <img class="text-gray-700 w-5 h-5" :src="route.icon" />
              <span class="ml-4">{{ route.name }}</span>
            </span>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import { WorkSpaceIcon } from '../../images'
import SidebarSubMenu from './SidebarSubMenu.vue'

export default {
  components: { SidebarSubMenu },
  data() {
    return {
      WorkSpaceIcon,
      routes: []
    }
  },
  mounted() {
    this.routes = this.$store.getters.sidebarContent
  },
  methods: {
    toggleSidebar(link) {
      this.$store.commit('toggleSidebar', false)
      if (this.$route.name.toLowerCase() === link.name.toLowerCase()) return
      this.$router.push(link)
    }
  }
}
</script>

<style></style>
