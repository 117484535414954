import MainBg from '../assets/images/travel-content.webp'
import MenuIcon from '../assets/svgs/ham_menu.svg'
import BellIcon from '../assets/svgs/bell.svg'
import AppointmentIcon from '../assets/svgs/appointment.svg'
import ClientIcon from '../assets/svgs/clients.svg'
import MessageIcon from '../assets/svgs/message.svg'
import PaymentIcon from '../assets/svgs/payment.svg'
import ReportIcon from '../assets/svgs/report.svg'
import TasksIcon from '../assets/svgs/tasks.svg'
import WorkSpaceIcon from '../assets/svgs/workspace.svg'
import DropdownIcon from '../assets/svgs/dropdown.svg'

export {
  DropdownIcon,
  MainBg,
  MenuIcon,
  BellIcon,
  AppointmentIcon,
  ClientIcon,
  MessageIcon,
  PaymentIcon,
  ReportIcon,
  TasksIcon,
  WorkSpaceIcon
}
